.welcome-section {
    padding: 20px;
    background-color: white;
}

.welcome-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.welcome-title {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
}

.welcome-subtitle {
    display: block;
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    color: #8c8c8c;
}

.menu-scroll-container {
    position: relative;
    margin-top: 16px;
    padding-bottom: 8px;
}

.menu-items {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding: 4px 0;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
}

.menu-items::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.menu-card {
    position: relative;
    min-width: 140px; /* Fixed width for scroll items */
    width: 140px;
    flex-shrink: 0;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
}

.menu-card:hover {
    transform: scale(1.03);
}

.menu-card-image-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* Creates a square aspect ratio */
}

.menu-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu-card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    color: white;
}

.menu-card-title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.menu-card-price {
    margin: 0;
    font-size: 13px;
    opacity: 0.9;
}

.veg-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 4px;
    border-radius: 4px;
}

.veg-icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #008000;
    border-radius: 50%;
}

/* Add this to your existing CSS */
.add-to-cart-button {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    z-index: 2;
}

.add-to-cart-button:hover {
    background-color: #cc0000;
}

/* Optional: Add a subtle shadow to make the button stand out */
.add-to-cart-button {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustment for the button */
@media (max-width: 767px) {
    .add-to-cart-button {
        padding: 3px 10px;
        font-size: 11px;
    }
}

/* Responsive adjustments */
@media (max-width: 767px) {
    .menu-card {
        min-width: 120px;
        width: 120px;
    }

    .menu-items {
        gap: 12px;
    }

    .menu-card-title {
        font-size: 12px;
    }

    .menu-card-price {
        font-size: 11px;
    }
}

/* Optional: Add smooth scrolling shadows */
.menu-scroll-container::before,
.menu-scroll-container::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 1;
    pointer-events: none;
}

.menu-scroll-container::before {
    left: 0;
    background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.menu-scroll-container::after {
    right: 0;
    background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
}

.add-to-cart-button.added {
  background-color: #4CAF50; /* or any color you prefer for the added state */
  cursor: default;
}