@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: red;
  --secondary-color: #2d2d2d;
  --background-color: #f4f4f2;
  --text-color: #333333;
  --white: #ffffff;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.logo {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
}

.search-bar {
  flex-grow: 1;
  margin: 0 20px;
}

.search-bar input {
  width: 100%;
  padding: 5px 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 14px;
}


.cart-icon {
  position: relative;
  color: var(--secondary-color);
}

.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 12px;
  font-weight: 600;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-categories {
  display: flex;
  overflow-x: auto;
  padding: 20px 0;
  margin-top: 80px;
}

.category-button {
  background-color: var(--white);
  color: var(--secondary-color);
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 25px;
  font-weight: 500;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.category-button.active {
  background-color: var(--primary-color);
  color: var(--white);
}

.menu-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.menu-item {
  background-color: var(--white);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  position: relative;
  overflow: visible;
}

.menu-item:hover {
  transform: translateY(-5px);
}

.menu-item-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.menu-item-content {
  padding: 15px;
}

.menu-item-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.menu-item-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.menu-item-price {
  font-weight: 700;
  color: var(--primary-color);
}


.quantity-controls {
  display: flex;
  align-items: center;
  margin-left: 60px; 
}

.quantity-btn {
  background-color: var(--secondary-color); 
  color: var(--white);
  border: none;
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 8px; 
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.quantity-btn:hover {
  background-color:red; 
}

.quantity-btn:active {
  transform: scale(0.95);
}

.quantity-display {
  font-size: 16px;
  padding: 0 10px; 
  font-weight: bold;
  font-family: 'Nerko One, sans-serif';
  color: var(--text-color); 
  display: inline-block;
  text-align: center; 
  min-width: 32px; 
}

.add-to-cart-btn {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 8px 15px;
  margin-left: 60px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-to-cart-btn:hover {
  background-color: red;
}

.add-to-cart-btn:active {
  transform: scale(0.95);
}

 

.drawer {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  background-color: var(--white);
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  transition: left 0.3s ease;
  z-index: 2000;
}

.drawer.open {
  left: 0;
}

.drawer-content {
  padding: 20px;
}

.drawer-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: var(--secondary-color);
}

.drawer-menu {
  margin-top: 50px;
}

.drawer-menu a {
  display: block;
  padding: 10px 0;
  font-size: 18px;
  color: var(--secondary-color);
  text-decoration: none;
  border-bottom: 1px solid #eee;
}

.home-container {
    padding-top: 80px;
  }
  
  .section-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--secondary-color);
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .category-card,
  .subcategory-card {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .category-card:hover,
  .subcategory-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  }
  
  .category-icon,
  .subcategory-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .category-card h3,
  .subcategory-card h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--secondary-color);
  }
  
  .back-button {
    background-color: transparent;
    border: none;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  @keyframes popBadge {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .ant-badge-count {
    animation: popBadge 0.3s ease-out;
  }
  
  .back-button:hover {
    text-decoration: underline;
  }
  
  .menu-items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }

  .order-summary-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
  }
  
  .order-summary-title {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
    color: #333;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 8px;
  }
  
  .order-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0;
    color: #555;
  }
  
  .item-name {
    font-weight: 500;
  }
  
  .item-price {
    font-weight: 500;
    color: #000;
  }
  
  .order-summary-total {
    margin-top: 20px;
    padding-top: 16px;
    border-top: 2px solid #e0e0e0;
  }
  
  .total-line {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.5rem;
    color: #000;
  }
  
  .pay-button {
    display: block;
    width: 100%;
    margin-top: 24px;
    padding: 12px;
    background-color: red;
    color: #fff;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pay-button:hover {
    background-color: red;
  }
  
  @media (max-width: 768px) {
    .order-summary-container {
      padding: 12px;
    }
  
    .order-summary-title {
      font-size: 1.5rem;
    }
  
    .order-item {
      font-size: 0.9rem;
    }
  
    .total-line {
      font-size: 1.25rem;
    }
  
    .pay-button {
      font-size: 1.1rem;
    }
  }

  .cart-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
  }
  
  .cart-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
  }
  
  .empty-cart-message {
    text-align: center;
    color: #888;
    font-size: 1.25rem;
    margin-top: 20px;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .item-info {
    max-width: 60%;
  }
  
  .item-name {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
  }
  
  .item-price {
    font-size: 1rem;
    color: #777;
    margin-top: 5px;
  }
  
  .item-actions {
    display: flex;
    align-items: center;
  }
  
  .quantity-button {
    background-color: #e0e0e0;
    border: none;
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .item-quantity {
    font-size: 1.25rem;
    color: #333;
  }
  
  .remove-button {
    background-color: transparent;
    color: red;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .cart-total {
    margin-top: 30px;
    text-align: right;
  }
  
  .total-text {
    font-size: 1.75rem;
    font-weight: bold;
    color: #000;
  }
  
  .place-order-button {
    display: block;
    width: 100%;
    background-color: red;
    color: #fff;
    text-align: center;
    padding: 12px 0;
    border-radius: 5px;
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 20px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .place-order-button:hover {
    background-color: red;
  }
  
  @media (max-width: 768px) {
    .cart-container {
      padding: 15px;
    }
  
    .cart-title {
      font-size: 1.75rem;
    }
  
    .cart-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .item-info {
      max-width: 100%;
      margin-bottom: 15px;
    }
  
    .item-actions {
      justify-content: space-between;
      width: 100%;
    }
  
    .total-text {
      font-size: 1.5rem;
    }
  
    .place-order-button {
      font-size: 1.1rem;
    }
  }
  
  
  @media (max-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  
    .category-card,
    .subcategory-card {
      padding: 15px;
    }
  
    .category-icon,
    .subcategory-icon {
      font-size: 30px;
    }
  
    .category-card h3,
    .subcategory-card h3 {
      font-size: 16px;
    }
  }

@media (max-width: 768px) {
  .header-content {
    flex-wrap: wrap;
  }
  
  .search-bar {
    order: 3;
    width: 100%;
    margin: 10px 0 0;
  }
  
  .menu-button {
    display: block;
  }
  
  .menu-categories {
    display: none;
  }
}

.order-confirmation-container {
  text-align: center;
  padding: 50px;
  color: #fff;
  background-color: white;
  border-radius: 30px;
  margin-top: 75px;
  max-width: 90%;
  margin: 75px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-confirmation-container h2 {
  color: black;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.order-confirmation-container p {
  color: black;
  font-size: 1.2em;
}

.btn-container {
  margin-top: 20px;
}

.order-button {
  text-decoration: none;
  color: white;
  background-color: red;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 5px;
  display: inline-block;
  font-size: 1em;
  transition: background-color 0.3s, color 0.3s;
}

.order-button:hover {
  background-color: red;
  color: #fff;
}

@media (max-width: 768px) {
  .order-confirmation-container {
    padding: 30px;
  }

  .order-confirmation-container h2 {
    font-size: 1.5em;
  }

  .order-confirmation-container p {
    font-size: 1em;
  }

  .order-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
